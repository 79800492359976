import React from "react";
import styles from "./Navbar.module.css";

export function Navbar() {
    return (
        <div>
            <nav id="navbar" className={styles.navbar}>
                {/* HERE GO ALL THE LINKS TO THE DIFFERENT SECTIONS */}
                <ul>
                    <li>
                        <a href="#welcome-section">Home</a>
                    </li>
                    <li>
                        <a href="#projects">Projects</a>
                    </li>
                    <li>
                        <a href="#contact-section">Contacts</a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}
