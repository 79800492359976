import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: 0,
};

export const portfolioSlice = createSlice({
    name: "portfolio",
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
        decrement: (state) => {
            state.value -= 1;
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload;
        },
    },
});

export const { increment, decrement, incrementByAmount } =
    portfolioSlice.actions;

export const selectCount = (state) => state.portfolio.value;

export default portfolioSlice.reducer;
