import React, { useEffect, useState } from "react";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFreeCodeCamp,
    faGithub,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

// COMPONENTS IMPORT
// import { Counter } from "./features/counter/Counter";
import { BackToTop } from "./features/back-to-top/BackToTop";
import { Navbar } from "./features/navbar/Navbar";
import { Project } from "./features/project/Project";

// IMAGES IMPORT
const pianteKeicher = require("./images/piante-keicher-screenshot.png");
// const randomQuoteMachine = require("./images/random-quote-machine-screenshot.png");
const familyBalanceApp = require("./images/family-balance-app-screenshot.png");
const markdownPreviewer = require("./images/markdown-previewer-screenshot.png");
// const drumMachine = require("./images/drum-machine-screenshot.png");
const javascriptCalculator = require("./images/javascript-calculator-screenshot.png");
const pomodoroTimer = require("./images/pomodoro-timer-screenshot.png");
const d3Collection = require("./images/d3-collection-screenshot.png");

function App() {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setShowButton(window.scrollY > 100);
        });
    }, []);

    // HERE I CAN ADD MORE PROJECTS AS JS OBJECTS
    const projects = [
        {
            link: "http://www.piantekeicher.com",
            img: pianteKeicher,
            alt: "piante keicher screenshot",
            title: "Piante Keicher Website",
            text: "Showcase website for a young plant production company. This website is built as a single page application with React.js, custom CSS and Bootstrap.",
        },
        // {
        //     link: "https://dnlk86.github.io/random-quote-machine",
        //     img: randomQuoteMachine,
        //     alt: "random quote machine screenshot",
        //     title: "Random Quote Machine",
        //     text: "A study project built with react.js and redux.js that randomly displays a famous phrase and its author, and lets the user re-tweet it.",
        // },
        {
            link: "https://familybalance.up.railway.app/",
            img: familyBalanceApp,
            alt: "family balance app screenshot",
            title: "Family Balance App",
            text: "A cost management fullstack app built with React & Redux on the front-end, Node & Express on the back-end and PostgreSQL for database.",
        },
        {
            link: "https://dnlk86.github.io/markdown-previewer",
            img: markdownPreviewer,
            alt: "markdown previewer screenshot",
            title: "Markdown Previewer",
            text: "An app built with create-react-app using the template react-redux and marked.js for parsing the input passed in the editor. The output of what the user types is displayed in the previewer.",
        },
        // {
        //     link: "https://dnlk86.github.io/drum-machine",
        //     img: drumMachine,
        //     alt: "drum machine screenshot",
        //     title: "Drum Machine",
        //     text: "A drum machine made with react.js that produces different sounds and colors depending on the button pressed by the user.",
        // },
        {
            link: "https://dnlk86.github.io/javascript-calculator",
            img: javascriptCalculator,
            alt: "javascript calculator screenshot",
            title: "JavaScript Calculator",
            text: "A simple calculator, that allows the user to perform basic mathematical operation, built with the JavaScript libraries react/redux and mathjs.",
        },
        {
            link: "https://dnlk86.github.io/pomodoro-timer",
            img: pomodoroTimer,
            alt: "pomodoro timer screenshot",
            title: "Pomodoro Timer",
            text: "A timer built with create-react-app using redux for state management, that allows to practice the famous pomodoro technique for time handling.",
        },
        {
            link: "https://dnlk86.github.io/data-driven-documents/#/",
            img: d3Collection,
            alt: "d3 collection screenshot",
            title: "D3.js Collection",
            text: "A charts and graphics collection website built with react.js and d3.js at its core and several other technologies.",
        },
    ];

    return (
        <div className="App" onsc>
            <header>
                <Navbar />
            </header>
            <section id="welcome-section">
                <div id="neon-container">
                    <h1 class="neon-text">Hi, I'm Daniel.</h1>
                    <h2 class="neon-text">A Web Developer.</h2>
                </div>
                <div id="about-container">
                    <h3>About me.</h3>
                    <p>
                        I am a junior full-stack Web Developer based in Latina,
                        Italy
                        <br />
                        Driven by personal curiosity and a desire to comprehend
                        the inner workings of the web, a few years ago I taught
                        myself how to code. Since then, my passion for coding
                        has remained strong, and I continuously learn and
                        explore new concepts.
                        <br />
                        I worked as a professional full-stack developer for 1.5
                        years, utilizing technologies such as Delphi (Object
                        Pascal), HTML, CSS, JS, MSSQL, and others.
                        <br />
                        I really enjoy building Websites and Web Apps leveraging
                        the power of JavaScript and the many tools available in
                        its ecosystem, and adding my own personal touch with
                        CSS. My chosen stack revolves around React.js for the
                        frontend, Node.js and Express.js for the backend, and
                        PostgreSQL for the database. This specialization enables
                        me to develop robust and dynamic applications throughout
                        the entire development stack.
                        <br />
                        Please take a look at my work, including this website,
                        and feel free to reach out if you are interested in
                        collaborating on a project.
                    </p>
                </div>
            </section>
            <section id="projects">
                {projects.map((proj) => (
                    <Project
                        link={proj.link}
                        img={proj.img}
                        alt={proj.alt}
                        title={proj.title}
                        text={proj.text}
                    />
                ))}
            </section>
            <section id="contact-section">
                {/* HERE GO THE LINKS TO MY GITHUB, FREECODECAMP AND OTHER SOCIAL MEDIA */}
                <div id="links-container">
                    <ul id="profile-links">
                        <li>
                            <a
                                href="https://www.linkedin.com/in/daniel-keicher-69b4b6210/"
                                target="_blank"
                                rel="noreferrer"
                                // style={{
                                //     color: "GrayText",
                                //     pointerEvents: "none",
                                // }}
                            >
                                <FontAwesomeIcon
                                    icon={faLinkedin}
                                    style={{ fontSize: "2rem" }}
                                />
                                {" LinkedIn"}
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://github.com/dnlk86"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faGithub}
                                    style={{ fontSize: "2rem" }}
                                />
                                {" GitHub"}
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.freecodecamp.org/dnlk"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faFreeCodeCamp}
                                    style={{ fontSize: "2rem" }}
                                />
                                {" FreeCodeCamp"}
                            </a>
                        </li>
                    </ul>
                </div>
                <footer id="foot">
                    <p>© Copyright 2023 - Daniel Keicher</p>
                </footer>
            </section>
            {showButton && <BackToTop />}
        </div>
    );
}

export default App;
