import React from "react";
import styles from "./Project.module.css";

export function Project(props) {
    return (
        <a
            className={styles.project}
            href={props.link}
            target="_blank"
            rel="noreferrer"
        >
            <img src={props.img} alt={props.alt} />
            <p>
                <span className={styles.cardTitle}>{props.title}</span>
                <hr />
                <span className={styles.cardText}>{props.text}</span>
            </p>
        </a>
    );
}
